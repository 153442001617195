<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <el-table :data="tableData" style="width: 100%">
      <!-- <el-table-column prop="co_id" label="序号" width="120" /> -->
      <el-table-column prop="co_name" label="姓名" width="250" />
      <el-table-column prop="co_mobile" label="电话" width="250" />
      <el-table-column label="留言时间" width="250" v-slot="{row}">
        <span>{{ row.co_created | dateTime }}</span>
      </el-table-column>
      <el-table-column prop="co_content" label="留言内容" v-slot="{ row }">
        <el-popover
          placement="top-start"
          trigger="hover"
          width="500"
          :content="row.co_content"
        >
          <div class="common-line-clamp-1" slot="reference">
            {{
              row.co_content
            }}
          </div>
        </el-popover>
      </el-table-column>
    </el-table>
    <el-row>
      <pagination
        :page-index="searchInfo.pageIndex"
        :page-size="searchInfo.pageSize"
        :total="total"
        @sizechange="handleSizeChange"
        @currentchange="handleCurrentChange"
        style="margin-top: 24px"
      />
    </el-row>
  </page-container>
</template>

<script>
import PageContainer from "@/components/common/PageContainer.vue";
import { webSiteApi } from "@/services/api";
export default {
  components: {
    PageContainer
  },
  data() {
    return {
      tableData: [],
      searchInfo: {
        pageIndex: 1,
        pageSize: 10
      },
      total: 0
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let { dataList, pageInfo } = await webSiteApi.messgeList(this.searchInfo);
      this.tableData = dataList;
      this.total = pageInfo.total;
      console.log(dataList);
    },
    handleSizeChange(value) {
      this.searchInfo.pageSize = value;
      this.getDataList();
    },
    handleCurrentChange(value) {
      this.searchInfo.pageIndex = value;
      this.getDataList();
    }
  }
};
</script>

<style scoped>
/deep/ .no_padding {
  padding: 0;
}
/deep/ .input_width input {
  width: 70%;
}
/deep/.el-input--mini .el-input__inner {
  height: 32px;
}
/deep/ .el-table__row {
  height: 60px!important;
}
.common-line-clamp-1 {
  color: #636363!important;
}
</style>
